import styled from 'styled-components';
import MediaQuery from 'web_component_library/utils/mediaQuery';

export const AddonList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.75em;
  list-style: none;
  padding: 0;
  flex-grow: 1;
`;

export const Li = styled.li`
  display: flex;
  flex-basis: 100%;
  margin: 0 0.75em 1em;
  ${MediaQuery.desktop`
    flex-basis: calc(33.333% - 1.5em);
    margin-bottom: 1.5em;
  `};
`;
