import MapLegend from 'web_component_library/mapLegend';
import WebpageIframe from 'web_component_library/webpageIframe';
import TextBlock from 'web_component_library/textBlock';

import PrepaidPlans from '../common/containers/plans/prepaidPlans';
import Addons from '../common/containers/addons';
import PromoCard from '../common/components/promoCard';
import LiveChatPromoCard from '../common/components/liveChatPromoCard';
import PromoBanner from '../common/components/promoBanner';
import PromoBannerL from '../common/components/promoBannerL';
import ComingSoonSection from '../common/components/comingSoonSection';
import QuestionAndAnswer from '../common/components/questionAndAnswer';
import SpatialBuzz from '../common/components/spatialBuzz';
import PageHeading from '../common/components/pageHeading';
import ThreeCategories from '../common/components/threeCategories';
import OverlappingCard from '../common/components/overlappingCard';
import Accessibility from '../common/components/accessibility';
import LegalTerms from '../common/components/legalTerms';
import PlanFeature from '../common/components/planFeature';
import SplitTextBlock from '../common/components/splitTextBlock';
import ContactUsToday from '../common/components/contactUsToday';
import ContactUsDetails from '../common/components/contactUsDetails';
import Separator from '../common/components/separator';
import SplitTextPopupButton from '../common/components/splitTextPopupButton';
import RichTextBlock from '../common/components/richTextBlock';
import RatesFilter from '../common/components/ratesFilter';
import RatesCountriesList from '../common/components/ratesCountriesList';
import AddonCards from '../common/containers/addonCards';
import PageSubHeading from '../common/components/pageSubHeading';
import LoginSection from '../common/components/loginSection';
import Article from '../common/components/article';
import PopularSearches from '../common/components/popularSearches';
import Accordion from '../common/components/accordion';
import EnhancedImage from '../common/components/enhancedImage';
import JSONComponent from '../common/components/jsonComponent';
import BenefitsSection from '../common/components/benefitsSection';
import WirelessPublicAlert from '../common/components/wirelessPublicAlert';
import Table from '../common/components/table';
import SideBySideTable from '../common/components/sideBySideTable';
import PageForm from '../common/containers/pageForm';
import FeaturesGrid from '../common/components/featuresGrid';
import LinksList from '../common/components/linksList';
import CompatibilityCheckSelection from '../common/containers/compatibilityCheckSelection';
import CompatibilityCheckResult from '../common/containers/compatibilityCheckResult';
import ImeiCompatibilityCheck from '../common/containers/imeiCompatibilityCheck';
import TradeInCalculator from '../common/containers/tradeInCalculator';
import SupportSearchInput from '../common/components/supportSearchInput';
import SupportSearchResults from '../common/components/supportSearchResults';
import SupportCategories from '../common/components/supportCategories';
import SupportFeedback from '../common/components/supportFeedback';
import { DeviceHelp } from '../common/components/deviceHelp';

import withCtaAnalytics from '../common/components/ctaAnalyticsHoc';
import ArticlePage from '../common/components/articlePage';
import TertiaryNavigationSection from '../common/components/tertiaryNavigationSection';
import ByopPlans from '../common/containers/plans/byopPlans';

const baseMapping = {
  textBlock: TextBlock,
  promoCard: PromoCard,
  liveChatPromoCard: LiveChatPromoCard,
  promoBanner: PromoBanner,
  comingSoonSection: ComingSoonSection,
  questionAndAnswer: QuestionAndAnswer,
  spatialBuzz: SpatialBuzz,
  webpageIframe: WebpageIframe,
  pageHeading: PageHeading,
  threeCategories: ThreeCategories,
  overlappingCard: OverlappingCard,
  accessibility: Accessibility,
  splitTextPopupButton: SplitTextPopupButton,
  legalTerms: LegalTerms,
  splitTextBlock: SplitTextBlock,
  contactUsToday: ContactUsToday,
  contactUsDetails: ContactUsDetails,
  planFeature: PlanFeature,
  byopPlans: ByopPlans,
  prepaidPlans: PrepaidPlans,
  separator: Separator,
  richTextBlock: RichTextBlock,
  addonPage: Addons,
  ratesFilter: RatesFilter,
  rates: RatesCountriesList,
  addonsSection: AddonCards,
  pageSubHeading: PageSubHeading,
  loginSection: LoginSection,
  article: Article,
  popularSearches: PopularSearches,
  accordion: withCtaAnalytics(Accordion, { includeModuleTitle: false }),
  enhancedImage: EnhancedImage,
  wirelessPublicAlert: WirelessPublicAlert,
  jsonComponent: JSONComponent,
  benefitsSection: BenefitsSection,
  mapLegend: MapLegend,
  table: Table,
  sideBySideTable: SideBySideTable,
  promoBannerL: PromoBannerL,
  pageForm: PageForm,
  featuresCard: withCtaAnalytics(FeaturesGrid, { includeSubtitle: true }),
  featuresIcons: withCtaAnalytics(FeaturesGrid, { includeSubtitle: true }),
  featuresPanel: withCtaAnalytics(FeaturesGrid, { includeSubtitle: true }),
  linksList: LinksList,
  compatibilityCheckSelection: CompatibilityCheckSelection,
  compatibilityCheckResult: CompatibilityCheckResult,
  imeiCompatibilityCheck: ImeiCompatibilityCheck,
  supportSearchInput: SupportSearchInput,
  supportSearchResults: SupportSearchResults,
  supportCategories: SupportCategories,
  supportFeedback: SupportFeedback,
  articlePage: ArticlePage,
  tertiaryNavigationSection: TertiaryNavigationSection,
  tradeInCalculator: TradeInCalculator,
  ozmoDeviceHelp: DeviceHelp,
};

// These components have more unique implementation for CTA analytics
const componentsToExclude = [
  'prepaidPlans',
  'byopPlans',
  'addonPage',
  'addonsSection',
  'featuresCard',
  'featuresIcons',
  'featuresPanel',
  'accordion',
  'ozmoDeviceHelp',
  'tradeInCalculator',
];

export const componentMapping = Object.keys(baseMapping).reduce(
  (obj, identifier) => {
    const Component = baseMapping[identifier];
    const includeCtaAnalytics = !componentsToExclude.some(
      key => key === identifier,
    );

    return {
      ...obj,
      [identifier]: includeCtaAnalytics
        ? withCtaAnalytics(Component)
        : Component,
    };
  },
  {},
);
