import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import { createStructuredSelector } from 'reselect';
import SmartConnectForm from 'web_component_library/smartConnectForm';
import { selectFormErrors } from '../contentfulHoc/selectors';
import { ModalContext } from '../../components/modal/modalContext';
import getEnvVar from '../../lib/envvars';
import withTranslation from '../translationHoc';
import { setUtagForLinks } from '../../lib/analytics';

export const noSubmissionOnCloseAnalyticsEvent = () =>
  setUtagForLinks({
    ev_type: 'component',
    ev_action: 'click',
    ev_name: 'close modal',
    ev_data: 'no submission',
  });

export const onRequestClose = () => {
  setUtagForLinks({
    ev_type: 'component',
    ev_action: 'click',
    ev_name: 'close modal',
    ev_data: 'submission success',
  });
};

export const SmartConnectFormContainer = ({
  description,
  formErrors,
  translate,
}) => {
  const { setProps, props } = useContext(ModalContext);
  const recaptchaKey = getEnvVar('RECAPTCHA_KEY');

  useEffect(() => {
    setUtagForLinks({
      ev_type: 'component',
      ev_action: 'click',
      ev_name: 'message us',
    });

    const id = 'recaptcha';
    let recaptchaScript = document.getElementById(id);
    // If the recaptcha script is not in the document then create it and append it to te body
    if (!recaptchaScript) {
      recaptchaScript = document.createElement('script');
      recaptchaScript.type = 'text/javascript';
      recaptchaScript.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaKey}`;
      recaptchaScript.id = id;
      document.body.appendChild(recaptchaScript);
      recaptchaScript.onload = () => {
        window.grecaptcha.ready(() => {
          const recaptchaBadge = document.querySelector('.grecaptcha-badge');
          recaptchaBadge.style.bottom = '7.50rem';
          recaptchaBadge.style.zIndex = 1051;
        });
      };
    }
    const recaptchaBadge = document.querySelector('.grecaptcha-badge');
    // We check if the recaptcha badge exists to make it visible if it does
    if (recaptchaBadge) recaptchaBadge.style.visibility = '';

    return () => {
      // On unmount hide the recaptcha badge
      const recaptchaBadgeOnUnmount = document.querySelector(
        '.grecaptcha-badge',
      );
      if (recaptchaBadgeOnUnmount)
        recaptchaBadgeOnUnmount.style.visibility = 'hidden';
    };
  }, []);

  const makeAnalyticsCall = submissionId => {
    setUtagForLinks({
      ev_type: 'component',
      ev_action: 'click',
      ev_name: 'submit',
      ev_data: `submission id: ${submissionId}`,
    });
  };

  const onSubmit = async (values, { setStatus }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const phoneNumber = values.phoneNumber.replace(/-/g, '');

      const body = {
        phoneNumber,
      };

      const url = `${getEnvVar(
        'microServiceApiBaseUrl',
      )}${'customermessaging/api/message/rcs'}`;

      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(recaptchaKey, { action: 'submit' })
          .then(async reCaptchaToken => {
            config.headers.reCaptchaToken = reCaptchaToken;
            const response = await axios.post(url, body, config);
            setStatus({
              success: true,
            });

            makeAnalyticsCall(response.data.messagesid);

            setProps({
              ...props,
              onRequestClose,
            });
          })
          .catch(() =>
            setStatus({
              error: formErrors.serverError,
            }),
          );
      });
    } catch (error) {
      /*
        possible error codes:
        1) reqParNll - invalid params (should be prevented via FE validation)
        2) gtMsgCntxtBdResp - generic error if any error returned by live person - treat similar to 500.
      */
      setStatus({
        error: formErrors.serverError,
      });
    }
  };

  return (
    <SmartConnectForm
      description={description}
      submitButtonLabel={translate('submitButtonLabel')}
      phoneInputLabel={translate('phoneInputLabel')}
      onSubmit={onSubmit}
      successMessage={translate('smartConnectSuccessMessage')}
      phoneNumberErrorMessage={translate('phoneNumberErrorMessage')}
    />
  );
};

SmartConnectFormContainer.propTypes = {
  description: PropTypes.object.isRequired,
  formErrors: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
};

const mapStateToProps = () =>
  createStructuredSelector({
    formErrors: selectFormErrors(),
  });

export default connect(mapStateToProps)(
  withTranslation(SmartConnectFormContainer),
);
