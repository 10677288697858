import React from 'react';
import PropTypes from 'prop-types';
import FeaturesCard from 'web_component_library/featuresCard';
import FeaturesIcon from 'web_component_library/featuresIcon';
import FeaturesPanel from 'web_component_library/featuresPanel';

const FeaturesGrid = ({
  description = null,
  eyebrow = null,
  featureColumnGroup,
  headingText,
  hideIcons = false,
  identifier,
  themeVariant,
}) => {
  if (identifier === 'featuresIcons') {
    return (
      <FeaturesIcon
        description={description}
        eyebrow={eyebrow}
        featureColumnGroup={featureColumnGroup}
        headingText={headingText}
        hideIcons={hideIcons}
      />
    );
  }

  if (identifier === 'featuresCard') {
    return (
      <FeaturesCard
        headingText={headingText}
        featureColumnGroup={featureColumnGroup}
        themeVariant={themeVariant}
      />
    );
  }

  return (
    <FeaturesPanel
      headingText={headingText}
      featureColumnGroup={featureColumnGroup}
      themeVariant={themeVariant}
    />
  );
};

FeaturesGrid.propTypes = {
  description: PropTypes.object,
  eyebrow: PropTypes.object,
  featureColumnGroup: PropTypes.array.isRequired,
  headingText: PropTypes.object.isRequired,
  hideIcons: PropTypes.bool,
  identifier: PropTypes.string.isRequired,
  themeVariant: PropTypes.string.isRequired,
};

export default FeaturesGrid;
