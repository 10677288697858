import { get } from 'lodash';

export const getBackground = backgroundData => {
  if (!backgroundData) {
    return { backgroundColor: '#000000' };
  }

  const {
    fields: { backgroundImage, gradientColors, solidColor },
  } = backgroundData;
  if (backgroundImage) {
    const url = get(backgroundImage, ['fields', 'file', 'url'], null);
    if (url) {
      return {
        backgroundImage: `url('${url}')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };
    }
  }
  if (gradientColors) {
    return {
      background: `linear-gradient(to right, ${gradientColors.join(', ')})`,
    };
  }

  return { backgroundColor: solidColor || '#000000' };
};
